import { useCallback, useEffect, useMemo } from 'react'
import { useSearchReviews, useWorkspaceDetails } from '.'
import { SearchQuery } from '../store/reducers/searchReviewReducer'

interface UseRiskReliefDeviationsProps {
  preventFetch?: boolean
  workspaceId?: string
}

const useRiskReliefDeviations = ({
  workspaceId,
  preventFetch = false,
}: UseRiskReliefDeviationsProps) => {
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const {
    refreshBatchSearchReviewList,
    refreshSearchReviewListDone,
    searchReviewList,
    loading,
  } = useSearchReviews({
    preventFetch: true,
  })

  const fetchSearchReviews = useCallback(() => {
    const searchReviewIds =
      selectedWorkspace?.config?.riskReliefConfig?.searchReviewIds
    if (searchReviewIds?.length) {
      refreshBatchSearchReviewList(searchReviewIds)
    } else {
      refreshBatchSearchReviewList([])
    }
  }, [selectedWorkspace, refreshBatchSearchReviewList])

  useEffect(() => {
    if (
      !loading &&
      !refreshSearchReviewListDone &&
      selectedWorkspace &&
      !preventFetch
    ) {
      fetchSearchReviews()
    }
  }, [
    fetchSearchReviews,
    loading,
    refreshSearchReviewListDone,
    selectedWorkspace,
    preventFetch,
  ])

  const deviationList = useMemo(() => {
    if (!searchReviewList?.length) return []

    const allQueries = searchReviewList.flatMap(
      (review) => review.searchQueries
    )

    if (!allQueries.length) return []

    const queryDeviations =
      selectedWorkspace?.config?.riskReliefConfig?.queryDeviations || []

    const relevantSearchQueryIds = queryDeviations.map(
      (queryDeviation) => queryDeviation.searchQueryId
    )

    if (!relevantSearchQueryIds.length) return []

    const matchingQueries = allQueries.filter(
      (query): query is SearchQuery =>
        !!query && relevantSearchQueryIds.includes(query.id)
    )

    return matchingQueries
  }, [searchReviewList, selectedWorkspace])

  return {
    deviationList,
    searchReviewList,
    loading,
  }
}

export default useRiskReliefDeviations
